/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    UPDATE_RMA_DETAILS,
    UPDATE_RMA_FORM_DATA,
    UPDATE_RMA_IS_LOADING,
    UPDATE_RMA_LIST
} from './RMA.action';

/** @namespace Pwa/Store/RMA/Reducer/getInitialState */
export const getInitialState = () => ({
    items: [],
    requestCount: 0,
    rmaDetails: {},
    formData: {},
    isLoading: false
});

/** @namespace Pwa/Store/RMA/Reducer/RMAReducer */
export const RMAReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_RMA_DETAILS:
        const { rmaDetail } = action;

        return {
            ...state,
            rmaDetails: rmaDetail
        };

    case UPDATE_RMA_LIST:
        const { rmaList, requestCount } = action;

        return {
            ...state,
            items: rmaList,
            requestCount
        };

    case UPDATE_RMA_FORM_DATA:
        const { formData } = action;

        return {
            ...state,
            formData
        };

    case UPDATE_RMA_IS_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default RMAReducer;
