/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    UPDATE_INVOICES_IS_LOADING,
    UPDATE_INVOICES_LIST
} from './Invoices.action';

/** @namespace Pwa/Store/Invoices/Reducer/getInitialState */
export const getInitialState = () => ({
    items: [],
    isLoading: false,
    invoiceCount: 0,
    invoiceStatuses: []
});

/** @namespace Pwa/Store/Invoices/Reducer/InvoicesReducer */
export const InvoicesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_INVOICES_LIST:
        const { invoicesList, invoiceCount = 0, invoiceStatuses = [] } = action;

        return {
            ...state,
            items: invoicesList,
            invoiceCount,
            invoiceStatuses
        };

    case UPDATE_INVOICES_IS_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default InvoicesReducer;
