/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * MyAccount Mutations
 * @class MyAccount
 * @namespace Pwa/Query/MyAccount/Query */
export class MyAccountQuery {
    /**
     * Get ResetPassword mutation
     * @param {{token: String, password: String, password_confirmation: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getResetPasswordMutation(options) {
        const { token, password, password_confirmation } = options;

        return new Field('s_resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument(
                'password_confirmation',
                'String!',
                password_confirmation
            )
            .addField('status');
    }

    /**
     * Get SignIn mutation
     * @param {{email: String, password: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getSignInMutation(options) {
        const { email, password } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addField('token');
    }

    getUpdateInformationMutation(options) {
        return new Field('updateCustomer')
            .addArgument('input', 'CustomerInput!', options)
            .addField(this._getCustomerField());
    }

    getUpdateSubaccountMutation(options) {
        return new Field('updateSubaccount')
            .addArgument('input', 'UpdateSubaccountInput!', options)
            .addField('message')
            .addField('status');
    }

    getRemoveSubaccountMutation(options) {
        return new Field('removeSubaccount')
            .addArgument('input', 'RemoveSubaccountInput!', options)
            .addField('message')
            .addField('status');
    }

    getCreateSubaccountMutation(options) {
        return new Field('createSubaccount')
            .addArgument('input', 'CreateSubaccountInput!', options)
            .addField('message')
            .addField('status');
    }

    getChangeCustomerPasswordMutation(options) {
        const { currentPassword, newPassword } = options;

        return new Field('changeCustomerPassword')
            .addArgument('currentPassword', 'String!', currentPassword)
            .addArgument('newPassword', 'String!', newPassword)
            .addField('id')
            .addField('email');
    }

    getCreateAddressMutation(options) {
        return new Field('createCustomerAddress')
            .addArgument('input', 'CustomerAddressInput!', options)
            .addFieldList(this._getAddressFields());
    }

    getDeleteAddressMutation(id) {
        return new Field('deleteCustomerAddress').addArgument('id', 'Int!', id);
    }

    getUpdateAddressMutation(id, options) {
        return new Field('updateCustomerAddress')
            .addArgument('id', 'Int!', id)
            .addArgument('input', 'CustomerAddressInput!', options)
            .addFieldList(this._getAddressFields());
    }

    getCreateAccountMutation(options) {
        const { customer, password } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password })
            .addField(this._getCustomerField());
    }

    getCreateAccountViaEmailMutation(options) {
        const {
            input = {}
        } = options;

        return new Field('consumeCustomerCreateForm')
            .addArgument('input', 'CustomerForm', input)
            .addFieldList(this._getConsumeCustomerCreateFormFields());
    }

    _getConsumeCustomerCreateFormFields() {
        return [
            'status',
            'message'
        ];
    }

    getConfirmAccountMutation(options) {
        const { key, email, password } = options;

        return new Field('confirmCustomerEmail')
            .addArgument('key', 'String!', key)
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addFieldList(this._getConfirmAccountFields());
    }

    getRevokeAccountToken(tokenOld) {
        return new Field('revokeCustomerToken2')
            .addArgument('tokenOld', 'String!', tokenOld)
            .addFieldList(this.getRevokeAccountTokenFields());
    }

    getCustomerQuery() {
        return this._getCustomerField();
    }

    getSubaccountsQuery() {
        return this._getCustomerSubAccountField();
    }

    _getConfirmAccountFields() {
        return ['status', 'token', this._getCustomerField()];
    }

    getRevokeAccountTokenFields() {
        return ['result'];
    }

    _getCustomerField() {
        return new Field('customer').addFieldList(this._getCustomerFields());
    }

    _getCustomerSubAccountField() {
        return new Field('getSubaccounts').addFieldList(this._getCustomerSubAccountsFields());
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'discount_patterns',
            'product_discount',
            'marking_discount',
            'currency',
            'pdfFee',
            'pdfPriceType',
            'is_subaccount',
            'trading_blocked',
            'trading_blocked_reason',
            'is_banned',
            'show_no_name_panel',
            'reference_number',
            this._getInvoiceInfoFields(),
            this._getAddressesField(),
            this._getOfferFields(),
            this._getConsultantFields(),
            new Field('order_statuses').addFieldList([
                'pending',
                'holded',
                'processing'
            ])
        ];
    }

    _getInvoiceInfoFields() {
        return new Field('invoice_info')
            .addFieldList([
                'credit_limit',
                'remaining_credit_amount',
                'to_pay_amount',
                'after_due_amount',
                'can_bank_transfer'
            ]);
    }

    _getOfferFields() {
        return new Field('offer')
            .addFieldList([
                'id',
                'items_count'
            ]);
    }

    _getConsultantFields() {
        return new Field('consultant')
            .addFieldList([
                'name',
                'phone',
                'email'
            ]);
    }

    _getCustomerSubAccountsFields() {
        return [
            'firstname',
            'lastname',
            'is_banned',
            'trading_blocked',
            'trading_blocked_reason',
            'email',
            'customer_id',
            this._getAddressesField()
        ];
    }

    _getAddressesField() {
        return new Field('addresses').addFieldList(this._getAddressFields());
    }

    _getRegionField() {
        return new Field('region').addFieldList(this._getRegionFields());
    }

    _getRegionFields() {
        return ['region_code', 'region', 'region_id'];
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'company',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField()
        ];
    }

    /**
     * Get ForgotPassword mutation
     * @param {{email: String}} options
     * @returns {Field}
     * @memberof MyAccount
     */
    getForgotPasswordMutation(options) {
        const { email } = options;

        return new Field('forgotPassword')
            .addArgument('email', 'String!', email)
            .addField('status');
    }
}

export default new MyAccountQuery();
