/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export const BEFORE_ITEMS_TYPE = 'BEFORE_ITEMS_TYPE';
export const SWITCH_ITEMS_TYPE = 'SWITCH_ITEMS_TYPE';
export const AFTER_ITEMS_TYPE = 'AFTER_ITEMS_TYPE';

// BEFORE_ITEMS_TYPE
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NAVIGATION_TABS = 'NAVIGATION_TABS';
export const DEMO_NOTICE = 'DEMO_NOTICE';
export const HEADER = 'HEADER';
export const BREADCRUMBS = 'BREADCRUMBS';
export const NEW_VERSION_POPUP = 'NEW_VERSION_POPUP';

// SWITCH_ITEMS_TYPE
export const HOME = 'HOME';
export const SEARCH = 'SEARCH';
export const CMS_PAGE = 'CMS_PAGE';
export const CART = 'CART';
export const CHECKOUT = 'CHECKOUT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const LOGIN = 'LOGIN';
export const ACCOUNT_FORGOT_PASSWORD = 'ACCOUNT_FORGOT_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const MY_ACCOUNT = 'MY_ACCOUNT';
export const MENU = 'MENU';
export const OFFER = 'OFFER';
export const SHARED_WISHLIST = 'SHARED_WISHLIST';
export const CONTACT_PAGE = 'CONTACT_PAGE';
export const COMPARE = 'COMPARE';
export const BLOG_POST_LISTING = 'BLOG_POST_LISTING';
export const BLOG_POST_DETAILS = 'BLOG_POST_DETAILS';
export const STYLE_GUIDE = 'STYLE_GUIDE';
export const URL_REWRITES = 'URL_REWRITES';
export const LOGIN_AS_CUSTOMER = 'LOGIN_AS_CUSTOMER';
export const RETURNS_PAGE = 'RETURNS_PAGE';
export const RETURNS_DETAILS = 'RETURNS_DETAILS';
export const RMA_PAGE = 'RMA_PAGE';
export const RMA_DETAILS = 'RMA_DETAILS';

// AFTER_ITEMS_TYPE
export const FOOTER = 'FOOTER';
export const COOKIE_POPUP = 'COOKIE_POPUP';

// ADDITIONAL
export const LANG_CURRENCY_CONFIG = {
    'en-GB': {
        currency: 'EUR',
        lang: 'en'
    },
    pl: {
        currency: 'PLN',
        lang: 'pl'
    },
    'pl-PL': {
        currency: 'PLN',
        lang: 'pl'
    },
    cs: {
        currency: 'CZK',
        lang: 'cz'
    }
};
